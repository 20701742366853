import { getRenderGraph } from '../utils/getRenderGraph'
import Page from '../components/Page/Page'
import { initializeApollo } from '../lib/apolloClient'
import { gql } from '@apollo/client'

const Index = (renderGraph: any) => {
  return <Page renderGraph={renderGraph} />
}

export default Index

export const getStaticProps = async ({ params }) => await getRenderGraph(`/${params.all.join('/')}`)

export async function getStaticPaths() {
  const apolloClient = initializeApollo()
  let paths = null

  try {
    const options = {
      query: gql`
        query {
          allPagePaths
        }
      `,
    }
    const response = await apolloClient.query(options)
    paths = response.data.allPagePaths
  } catch (e) {
    console.error('Error retrieving allPagePaths', JSON.stringify(e, null, '  '))
  }
  return { paths, fallback: false }
}
